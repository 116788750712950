<!-- @/shared/components/dynamic/blocks/KeyValuePairDisplay.vue -->
<template>
    <div class="flex w-full flex-col gap-4 py-2 md:gap-5">
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click.stop="emit('connect-summary', 1)"
        >
            Connect first summary
        </v-button>
        <template
            v-for="(summary, index) in summaries"
            :key="summary.title"
        >
            <template v-if="summary.loading">
                <p class="animate-skeleton w-2/3">Loading summary...</p>
                <p class="animate-skeleton w-2/3">Loading summary...</p>
                <p class="animate-skeleton w-2/3">Loading summary...</p>
            </template>
            <summary-block
                v-else
                is="div"
                :user-summary-content="summary.text"
                :user-summary-id="summary.id"
                :mentions="summary.mentions"
                :content-with-slugs="summary.contentWithSlugs"
                class="relative"
            >
                <key-value-pair-display-item
                    :text="summary.text"
                    :title="summary.title"
                    :user-summary-id="summary.id"
                    :mentions="summary.mentions"
                    :content-with-slugs="summary.contentWithSlugs"
                />
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', summary.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
            <v-button
                v-if="editMode?.active"
                class="self-start"
                @click.stop="emit('connect-summary', index + 2)"
            >
                Connect summary here
            </v-button>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { SummaryComponentSummary } from '@/shared/types/page'
import KeyValuePairDisplayItem from '@/shared/components/dynamic/blocks/KeyValuePairDisplayItem.vue'
import VButton from '@/shared/components/VButton.vue'
import { inject } from 'vue'
import { editModeInjection } from '@/shared/types/injection'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import SummaryBlock from '@/shared/components/SummaryBlock.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    summaries: {
        type: Array<SummaryComponentSummary>,
        default: () => [],
    },
})

const editMode = inject(editModeInjection)
</script>

<style scoped></style>
