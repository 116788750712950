<!-- @/shared/components/dynamic/blocks/ListBlock.vue -->
<template>
    <div class="flex w-full flex-col gap-4 py-2 md:gap-5">
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click.stop="emit('connect-summary', 1)"
        >
            Connect first summary
        </v-button>
        <template
            v-for="(summary, index) in summaries"
            :key="summary.title"
        >
            <summary-block
                is="div"
                :user-summary-content="summary.content"
                :user-summary-id="summary.id"
                class="relative"
            >
                <div v-if="isUnorderedList(summary.type)">
                    <ul class="list-disc pl-5">
                        <li
                            v-for="(item, index) in summary.list"
                            :key="item"
                        >
                            <summary-block-mentions-renderer
                                :content-with-slugs="
                                    summary.contentWithSlugs[index]
                                "
                                :mentions="summary.mentions"
                                :user-summary-content="item"
                            />
                        </li>
                    </ul>
                </div>
                <div v-else-if="isOrderedList(summary.type)">
                    <ol class="list-decimal pl-5">
                        <li
                            v-for="item in summary.list"
                            :key="item"
                        >
                            <summary-block-mentions-renderer
                                :content-with-slugs="
                                    summary.contentWithSlugs[index]
                                "
                                :mentions="summary.mentions"
                                :user-summary-content="item"
                            />
                        </li>
                    </ol>
                </div>
                <div v-else>
                    <key-value-pair-display-item
                        :text="summary.text"
                        :title="summary.title"
                        :mentions="summary.mentions"
                        :content-with-slugs="summary.contentWithSlugs"
                    />
                </div>
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', summary.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
            <v-button
                v-if="editMode?.active"
                class="self-start"
                @click.stop="emit('connect-summary', index + 2)"
            >
                Connect summary here
            </v-button>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { SummaryComponentSummary } from '@/shared/types/page'
import KeyValuePairDisplayItem from '@/shared/components/dynamic/blocks/KeyValuePairDisplayItem.vue'
import VButton from '@/shared/components/VButton.vue'
import { inject } from 'vue'
import { editModeInjection } from '@/shared/types/injection'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import SummaryBlock from '@/shared/components/SummaryBlock.vue'
import SummaryBlockMentionsRenderer from '@/shared/components/SummaryBlockMentionsRenderer.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    summaries: {
        type: Array<SummaryComponentSummary>,
        default: () => [],
    },
})

const editMode = inject(editModeInjection)

const isUnorderedList = (type: string) => type === 'unordered_list'
const isOrderedList = (type: string) => type === 'ordered_list'
</script>

<style scoped></style>
