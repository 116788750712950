<template>
    <router-link
        :class="getComputedClass"
        :style="getDynamicStyle"
        :disabled="disableLink"
        :to="card.path"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        class="flex w-full cursor-pointer select-none items-start gap-4 p-4 duration-75"
    >
        <achievement-progress-circle
            :color="achievement?.color"
            :icon="achievement?.icon?.thumbnail"
            :percent="progress ?? card.percent"
            :time="(achievement?.time / 60).toFixed(0) + ' min'"
        />
        <div class="flex w-full flex-col items-start gap-0">
            <div class="flex w-full flex-col gap-0">
                <div
                    v-if="card?.details"
                    :style="`color: ${achievement?.color};`"
                    class="flex flex-row gap-2 items-center w-full text-3xs font-bold uppercase leading-tight"
                >
                    <div>
                        {{ card.target_type }}
                    </div>
                    <template
                        v-for="(detail, index) in card.details"
                        :key="index"
                    >
                        <span class="text-black opacity-50 line-clamp-1">
                            {{ detail }}
                        </span>
                        <point-icon v-if="index !== card.details.length - 1" />
                    </template>
                </div>
                <h5
                    class="my-0 py-0 text-lg font-semibold text-black opacity-90"
                >
                    {{ achievement?.title }}
                </h5>
            </div>
            <div v-if="expanded" class="text-base font-normal text-black opacity-80">
                {{ achievement?.description }}
            </div>
        </div>
    </router-link>
</template>

<script lang="ts" setup>
import PointIcon from '@/shared/components/icons/PointIcon.vue'
import AchievementProgressCircle from '@/shared/components/AchievementProgressCircle.vue'
import { hexToRgba } from '@/shared/utils/colorUtils'
import { computed, PropType, ref } from 'vue'
import { UserAchievement } from '@/shared/types/userAchievement'
import { useRoute } from 'vue-router'

const props = defineProps({
    card: {
        type: Object as PropType<UserAchievement>,
        required: true,
    },
    showStoryName: { type: Boolean, default: true },
    disableLink: { type: Boolean, default: false },
    progress: { type: Number, default: () => undefined },
    expanded: { type: Boolean, default: true },
})

const route = useRoute()

const hovered = ref(false)

const getComputedClass = computed(() => {
    return {
        'cursor-default': props.disableLink,
        'hover:scale-101': true,
        'border': true,
        'rounded-lg': true,
    }
})

const getDynamicStyle = computed(() => {
    const baseAlpha = hovered.value ? 20 : 10;
    return {
        background: `linear-gradient(0deg, ${achievementColorWithTransparency(15)} 0%, ${achievementColorWithTransparency(10)} 100%), #fff`,
        color: achievementColorWithTransparency(10),
        borderColor: achievementColorWithTransparency(baseAlpha),  // Changes alpha on hover
    }
})

function achievementColorWithTransparency(alpha: number) {
    return hexToRgba(achievement.value.color, alpha)
}


const achievement = computed(() => {
    return props.card.achievement;
})

</script>

<style scoped></style>
