<template>
    <article
        class="h-75 m-auto flex w-full max-w-[616px] flex-col overflow-hidden rounded-xl border border-solid border-stone-200 bg-white text-base"
    >
        <header
            class="flex w-full flex-wrap items-center justify-between gap-10 border-b border-solid border-b-stone-300 px-7 py-4 text-sm font-semibold max-md:max-w-full max-md:px-5"
        >
            <nav class="my-auto flex items-center gap-6 self-stretch">
                <span
                    :class="{
                        'text-stone-800': activeTab === 'details',
                        'text-stone-400': activeTab !== 'details',
                    }"
                    class="my-auto cursor-pointer self-stretch text-ellipsis"
                    @click="activeTab = 'details'"
                >
                    {{initialDetails?.model_type ?? 'Element'}} Details
                </span>
                <span
                    :class="{
                        'text-stone-800': activeTab === 'aliases',
                        'text-stone-400': activeTab !== 'aliases',
                    }"
                    class="my-auto cursor-pointer self-stretch text-ellipsis"
                    @click="activeTab = 'aliases'"
                >
                    Aliases ({{ details.aliases.length }})
                </span>
            </nav>
            <button @click="emit('close')">
                <xmark-icon class="my-auto h-5 shrink-0 text-stone-400" />
            </button>
        </header>

        <main
            class="flex max-h-[600px] min-h-[288px] w-full flex-col overflow-scroll px-7 py-5 max-md:max-w-full max-md:px-5"
        >
            <!-- Character Details Tab -->
            <div v-if="activeTab === 'details'">
                <div
                    v-if="details?.type === 'short'"
                    class="flex w-full flex-col max-md:max-w-full"
                >
                    <label
                        class="tracking-wide text-stone-500"
                        for="firstName"
                    >
                        Name
                    </label>
                    <input
                        id="name"
                        v-model="details.name"
                        :disabled="isLoading"
                        :placeholder="initialDetails?.name || 'Enter name'"
                        class="mt-2 min-h-[40px] w-full gap-1 self-stretch whitespace-nowrap rounded bg-stone-100 px-3 py-2 text-stone-700 max-md:max-w-full"
                    />
                </div>
                <template v-else>
                    <div class="flex w-full flex-col max-md:max-w-full">
                        <label
                            class="tracking-wide text-stone-500"
                            for="firstName"
                        >
                            First name
                        </label>
                        <input
                            id="firstName"
                            v-model="details.first_name"
                            :disabled="isLoading"
                            :placeholder="
                                initialDetails?.first_name || 'Enter first name'
                            "
                            class="mt-2 min-h-[40px] w-full gap-1 self-stretch whitespace-nowrap rounded bg-stone-100 px-3 py-2 text-stone-700 max-md:max-w-full"
                        />
                    </div>
                    <div class="mt-6 flex w-full flex-col max-md:max-w-full">
                        <label
                            class="tracking-wide text-stone-500"
                            for="middleName"
                        >
                            Middle name
                        </label>
                        <input
                            id="middleName"
                            v-model="details.middle_name"
                            :disabled="isLoading"
                            :placeholder="
                                initialDetails?.middle_name ||
                                'Enter middle name'
                            "
                            class="mt-2 min-h-[40px] w-full gap-1 self-stretch whitespace-nowrap rounded bg-stone-100 px-3 py-2 text-stone-700 max-md:max-w-full"
                        />
                    </div>
                    <div class="mt-6 flex w-full flex-col max-md:max-w-full">
                        <label
                            class="tracking-wide text-stone-500"
                            for="lastName"
                        >
                            Last name
                        </label>
                        <input
                            id="lastName"
                            v-model="details.last_name"
                            :disabled="isLoading"
                            :placeholder="
                                initialDetails?.last_name || 'Enter last name'
                            "
                            class="mt-2 min-h-[40px] w-full gap-1 self-stretch whitespace-nowrap rounded bg-stone-100 px-3 py-2 text-stone-700 max-md:max-w-full"
                        />
                    </div>
                </template>
            </div>

            <!-- Aliases Tab -->
            <div
                v-if="activeTab === 'aliases'"
                class="flex w-full flex-col max-md:max-w-full"
            >
                <div
                    v-for="(alias, index) in details.aliases"
                    :key="'alias-' + index"
                    class="mt-2 flex w-full items-start gap-3 max-md:max-w-full"
                >
                    <div
                        class="flex w-full min-w-[240px] flex-1 shrink basis-0 flex-col max-md:max-w-full"
                    >
                        <label
                            v-if="index === 0"
                            class="text-stone-500"
                        >
                            Aliases
                        </label>
                        <div
                            class="mt-2 flex w-full items-center gap-3 text-stone-700 max-md:max-w-full"
                        >
                            <input
                                v-model="details.aliases[index]"
                                :disabled="isLoading"
                                class="my-auto min-h-[40px] w-full min-w-[240px] flex-1 shrink gap-1 self-stretch rounded bg-stone-100 px-3 py-2 max-md:max-w-full"
                                placeholder="Enter alias"
                                type="text"
                                @keydown.delete="
                                    () => {
                                        if (alias.length === 0) {
                                            details.aliases.splice(index, 1)
                                        }
                                    }
                                "
                            />
                            <button
                                class="p-1"
                                @click="details.aliases.splice(index, 1)"
                            >
                                <trash-icon class="h-4" />
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    class="mt-2 flex items-center gap-1 font-medium text-stone-400"
                    @click="addNewAlias"
                >
                    <span class="">Add new</span>
                    <plus-icon class="h-4 shrink-0 text-stone-400" />
                </button>
            </div>
        </main>

        <footer
            class="flex w-full items-center justify-between bg-white px-7 py-4 text-center font-bold text-white max-md:max-w-full max-md:px-5"
        >
            <div
                class="my-auto flex w-full min-w-[240px] flex-1 shrink basis-0 flex-col self-stretch max-md:max-w-full"
            >
                <div
                    class="flex w-full flex-col items-center max-md:max-w-full"
                >
                    <button
                        :disabled="!isDirty"
                        class="min-h-[40px] gap-2 self-stretch rounded-[48px] bg-stone-800 hover:bg-stone-950 px-5 py-3 disabled:cursor-not-allowed disabled:opacity-50"
                        @click="saveUpdates"
                    >
                        Save updates
                    </button>
                </div>
            </div>
        </footer>
    </article>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import XmarkIcon from '@/shared/components/icons/XmarkIcon.vue'
import PlusIcon from '@/shared/components/icons/PlusIcon.vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import axios from 'axios'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import api from '@/shared/utils/api'

const emit = defineEmits(['close', 'saved'])

const props = defineProps({
    element: {
        type: Object,
        required: true,
    },
    projectSlug: {
        type: String,
        required: true,
    },
})

const activeTab = ref('details')
const enabled = ref(true)

type Details = {
    name: string
    type: 'person' | 'short'
    model_type: string
    first_name: string | undefined
    middle_name: string | undefined
    last_name: string | undefined
    aliases: string[]
}

const details = ref<Details>({
    name: '',
    type: 'person',
    first_name: '',
    middle_name: '',
    last_name: '',
    aliases: [],
})
const queryClient = useQueryClient()
const { data: initialDetails, isLoading } = useQuery<Details>({
    queryKey: ['details', props.projectSlug, props.element.slug],
    refetchOnMount: false,
    queryFn: async () => {
        return (
            await axios.get(
                `/api/v1/projects/${props.projectSlug}/elements/${props.element.slug}`
            )
        ).data.data
    },
    select: (data) => {
        details.value = { ...data, aliases: [...data.aliases] ?? [] }
        return data
    },
})

const isDirty = computed(() => {
    return (
        !isLoading.value &&
        (details.value.name !== initialDetails.value?.name ||
            details.value.first_name !== initialDetails.value?.first_name ||
            details.value.middle_name !== initialDetails.value?.middle_name ||
            details.value.last_name !== initialDetails.value?.last_name ||
            details.value.aliases.length !==
                initialDetails.value?.aliases.length ||
            JSON.stringify(details.value.aliases) !==
                JSON.stringify(initialDetails.value?.aliases))
    )
})

const addNewAlias = () => {
    details.value.aliases.push('')
}

const saveUpdates = () => {
    enabled.value = false

    details.value.aliases = details.value.aliases.filter(
        (alias) => alias.trim().length > 0
    )

    api.put(
        `/api/v1/projects/${props.projectSlug}/elements/${props.element.slug}`,
        details.value
    ).then((res) => {
        queryClient.setQueryData(
            ['details', props.projectSlug, props.element.slug],
            { ...res.data! }
        )
        emit('saved')
        emit('close')
    })
}
</script>

<style lang="css" scoped>
input {
    outline: none;
}
</style>
