<template>
    <section
        class="titleSection flex w-full flex-col items-start gap-3 text-left"
    >
        <slot name="title">
            <div class="flex w-full items-center justify-between">
                <h4
                    v-if="getTitle"
                    :class="titleClass"
                    class="select-none"
                >
                    {{ getTitle }}
                </h4>
                <div class="flex gap-2">
                    <add-element-button
                        v-if="addButton"
                        :exclude="addButton.exclude"
                        :model-plural="addButton.model_plural"
                        :parent-slug="addButton.parent_slug"
                        :project-slug="addButton.project_slug"
                        @refresh="emit('reload')"
                    />
                    <router-link
                        v-if="link"
                        :to="link"
                        class="flex cursor-pointer select-none items-center py-1 pl-2 text-sm text-stone-400 hover:text-stone-600"
                    >
                        {{ linkText ?? 'View All' }}
                    </router-link>
                </div>
            </div>
        </slot>
        <slot />
    </section>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import AddElementButton from '@/shared/components/cards/AddElementButton.vue'

const emit = defineEmits(['reload'])

const props = defineProps({
    title: {
        type: String,
        default: () => undefined,
    },
    link: {
        type: [String, Object] as PropType<RouteLocationRaw | undefined>,
        default: () => undefined,
    },
    linkText: {
        type: String,
        default: () => undefined,
    },
    addButton: {
        type: Boolean,
        default: false,
    },
    titleClass: {
        type: String,
        default: () => undefined,
    },
    variant: {
        type: String,
        default: 'default',
    },
})

const getTitle = computed(() => {
    if (props.title && props.title !== 'Default') {
        return props.title
    }
    return undefined
})

const titleClass = computed(() => {
    if (props.titleClass) {
        return props.titleClass
    }

    switch (props.variant) {
        case 'muted':
            return 'text-sm font-semibold text-neutral-300'
        case 'lg':
            return 'text-lg md:text-2xl text-stone-950 font-semibold'
        default:
            return 'text-lg md:text-2xl text-stone-950 font-semibold'
    }
})
</script>
