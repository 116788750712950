<template>
    <div
        :class="{
            'border-4 border-green-500': editMode?.active,
        }"
        class="component flex w-full flex-col gap-1"
    >
        <div
            v-if="contentComponent.title"
            class="componentTitle w-full select-none text-lg font-semibold text-stone-900"
        >
            {{ contentComponent.title }}
        </div>
        <achievement-in-progress-card
            v-if="isAchievementInProgressCard"
            :card="props.contentComponent.props"
        />
        <!--        <template-->
        <!--            v-else-if="contentComponent.vue_component === 'ElementCard'">-->
        <!--            <pre>{{props.contentComponent.props}}</pre>-->
        <!--        </template>-->
        <narrative-element-card
            v-else-if="contentComponent.vue_component === 'ElementCard'"
            hide-buttons="upload"
            show-dropdown
            v-bind="props.contentComponent"
            @delete="
                modal.show(
                    'delete-narrative-element',
                    {
                        title: 'Delete',
                        message: `Are you sure you want to delete ${contentComponent.element.title}?`,
                    },
                    {
                        onConfirm: () =>
                            deleteNarrativeElement(
                                projectSlug,
                                contentComponent.element.model_plural,
                                contentComponent.element.slug,
                                false
                            ).then(() => {
                                emit('reload')
                            }),
                    }
                )
            "
            @edit="
                modal.show(
                    'edit-narrative-element',
                    {
                        element: contentComponent.element,
                        projectSlug,
                    },
                    {
                        onSaved: () => emit('reload'),
                    }
                )
            "
            @delete-connection="
                modal.show(
                    'delete-narrative-element',
                    {
                        title: 'Remove Connection',
                        message: `Are you sure you want to remove connection to ${parentElement.title}?`,
                    },
                    {
                        onConfirm: () =>
                            deleteNarrativeElementConnection(
                                projectSlug,
                                parentElement.slug,
                                contentComponent.element.slug
                            ).then(() => {
                                emit('reload')
                            }),
                    }
                )
            "
        />
        <component
            :is="component"
            v-else-if="component"
            :card="
                props.contentComponent.card ??
                props.contentComponent?.props?.achievement
            "
            :element="props.contentComponent?.element"
            :isCondensed="false"
            :summaries="props.contentComponent.summaries"
            @delete="deleteSummary"
            @connect-summary="addSummary"
        />
        <div
            v-else
            class="bg-red-500 p-2"
        >
            Failed to load component {{ props.contentComponent.vue_component }}
            <pre>{{ props.contentComponent }}</pre>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, PropType } from 'vue'
import { ContentComponent } from '@/shared/types/page'
import StandardContentBlock from '@/shared/components/dynamic/blocks/StandardContentBlock.vue'
import KeyValuePairDisplay from '@/shared/components/dynamic/blocks/KeyValuePairDisplay.vue'
import ListBlock from '@/shared/components/dynamic/blocks/ListBlock.vue'
import MomentCard from '@/shared/components/cards/MomentCard.vue'
import SentimentContentBlock from '@/shared/components/dynamic/blocks/SentimentContentBlock.vue'
import ArticleBlock from '@/shared/components/dynamic/blocks/ArticleBlock.vue'
import AchievementInProgressCard from '@/shared/components/cards/AchievementInProgressCard.vue'
import { editModeInjection } from '@/shared/types/injection'
import api from '@/shared/utils/api'
import useModal from '@/shared/composables/useModal'
import StoryCard from '@/shared/components/story/StoryCard.vue'
import CreateStoryCard from '@/shared/components/story/CreateStoryCard.vue'
import AchievementCompletedCard from '@/shared/components/cards/AchievementCompletedCard.vue'
import DynamicBanner from '@/shared/components/ui/DynamicBanner.vue'
import NarrativeElementCard from '@/shared/components/cards/NarrativeElementCard.vue'
import useNarrativeElementActions from '@/shared/composables/narrativeElementActions'

const emit = defineEmits(['reload'])

const props = defineProps({
    contentComponent: {
        type: Object as PropType<ContentComponent>,
        required: true,
    },
})

const editMode = inject(editModeInjection)
const projectSlug = inject('project_slug')
const parentElement = inject('parent_element')

const modal = useModal()
const { deleteNarrativeElement, deleteNarrativeElementConnection } =
    useNarrativeElementActions()

const isAchievementInProgressCard = computed(() => {
    return props.contentComponent.vue_component === 'AchievementInProgressCard'
})

const component = computed(() => {
    switch (props.contentComponent.vue_component) {
        case 'StandardContentBlock':
            return StandardContentBlock
        case 'KeyValuePairDisplay':
            return KeyValuePairDisplay
        case 'ListBlock':
            return ListBlock
        case 'MomentCard':
            return MomentCard
        case 'SentimentContentBlock':
            return SentimentContentBlock
        case 'ArticleBlock':
            return ArticleBlock
        case 'AchievementInProgressCard':
            return AchievementInProgressCard
        case 'ProjectCard':
            return StoryCard
        case 'AddProjectCard':
            return CreateStoryCard
        case 'AchievementCard':
            return AchievementCompletedCard
        case 'Banner':
            return DynamicBanner
        case 'ElementCard':
            return NarrativeElementCard
        default:
            return null
    }
})

function getComponentTypes(componentType: string) {
    switch (componentType) {
        case 'ListBlock':
            return ['unordered_list', 'ordered_list', 'paragraph']
        case 'MomentCard':
            return ['title', 'paragraph', 'dialogue']
        case 'SentimentContentBlock':
            return [
                'positive_paragraph',
                'negative_paragraph',
                'positive_list',
                'negative_list',
            ]
        case 'ArticleBlock':
            return ['paragraphs']
        default:
            return null
    }
}

function addSummary(index: number) {
    console.log(index)
    const types = getComponentTypes(props.contentComponent.vue_component)
    modal.show(
        'form',
        {
            fields: [
                {
                    name: 'display_name',
                    label: 'Display Name',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'summary_id',
                    label: 'Summary',
                    type: 'summary',
                    required: true,
                },
                types
                    ? {
                          name: 'type',
                          label: 'Type',
                          type: 'select',
                          required: true,
                          options: types.map((type) => ({
                              value: type,
                              label: type,
                          })),
                          default: types[0],
                      }
                    : undefined,
            ].filter(Boolean),
        },
        {
            onConfirm: (payload, close) => {
                const [data] = payload
                api.post(
                    `/api/v1/components/${props.contentComponent.id}/summaries`,
                    {
                        order: index,
                        type: 'paragraph',
                        ...data,
                    }
                ).then(() => {
                    emit('reload')
                })
            },
        }
    )
}

function deleteSummary(id: number | string) {
    modal.show(
        'confirm',
        {
            title: 'Delete Summary',
            message: 'Are you sure you want to delete this summary?',
        },
        {
            onConfirm: (close) => {
                api.delete(
                    `/api/v1/components/${props.contentComponent.id}/summaries/${id}`
                ).then(() => {
                    emit('reload')
                })
            },
        }
    )
}
</script>

<style scoped></style>
