<template>
    <svg
        fill="none"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.63538 14.3333H4.00065C2.52789 14.3333 1.33398 13.1394 1.33398 11.6667V3.66667C1.33398 2.19391 2.52789 1 4.00065 1H12.0007C13.4734 1 14.6673 2.19391 14.6673 3.66667V6.66667M1.33398 9L3.29332 10.4107C4.31145 11.1438 5.70286 11.068 6.63538 10.2287L9.36593 7.77125C10.096 7.11418 11.1074 6.9251 12.0007 7.22575M7.33398 5.33333C7.33398 6.25381 6.58779 7 5.66732 7C4.74684 7 4.00065 6.25381 4.00065 5.33333C4.00065 4.41286 4.74684 3.66667 5.66732 3.66667C6.58779 3.66667 7.33398 4.41286 7.33398 5.33333Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.2"
        />
        <path
            d="M15.5583 10.2501C15.7028 10.1202 15.7028 9.90353 15.5583 9.78021L14.6918 9.00032C14.5548 8.87034 14.3141 8.87034 14.1697 9.00032L13.4883 9.61023L14.877 10.8601M9 13.653V14.9028H10.3887L14.4844 11.2133L13.0957 9.96352L9 13.653Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
