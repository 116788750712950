import { useRouter } from 'vue-router'
import api from '@/shared/utils/api'

export default function useNarrativeElementActions() {
    const router = useRouter()

    return {
        deleteNarrativeElement: async (
            project_slug: string,
            model_plural: string,
            element_slug: string,
            redirect: boolean = true
        ) => {
            const response = await api.delete(
                `/api/v1/projects/${project_slug}/${model_plural}/${element_slug}`
            )

            if (redirect) {
                if (model_plural === 'stories') {
                    void router.push('/')
                } else {
                    void router.push({
                        name: 'project',
                        params: {
                            project_slug: project_slug,
                        },
                    })
                }
            }

            return response
        },

        deleteNarrativeElementConnection: async (
            project_slug: string,
            parent_slug: string,
            element_slug: string
        ) => {
            return await api.delete(
                `/api/v1/projects/${project_slug}/elements/${parent_slug}/connections/${element_slug}`
            )
        },
    }
}
