<template>
    <svg
        class="lucide lucide-grip-vertical"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="9"
            cy="12"
            r="1"
        />
        <circle
            cx="9"
            cy="5"
            r="1"
        />
        <circle
            cx="9"
            cy="19"
            r="1"
        />
        <circle
            cx="15"
            cy="12"
            r="1"
        />
        <circle
            cx="15"
            cy="5"
            r="1"
        />
        <circle
            cx="15"
            cy="19"
            r="1"
        />
    </svg>
</template>
