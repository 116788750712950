<template>
    <DropdownMenu>
        <DropdownMenuTrigger
            class="flex cursor-pointer select-none items-center py-1 pl-2 text-sm text-stone-400 hover:text-stone-600"
        >
            <plus-icon class="h-2" />
            Add
        </DropdownMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuItem @click="create">
                <plus-diamond-icon class="mr-1 h-4" />
                Create New
            </DropdownMenuItem>
            <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                    <search-icon class="mr-1 h-4" />
                    Add Existing
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                    <DropdownMenuSubContent class="w-56 p-2">
                        <narrative-element-search
                            :exclude="exclude"
                            :model-plural="modelPlural"
                            :project-slug="projectSlug"
                        >
                            <search-bar />
                            <search-results v-slot="{ result }">
                                <DropdownMenuItem
                                    as-child
                                    @click="attach(result.slug)"
                                >
                                    <narrative-element-mini-card
                                        :element="result"
                                    />
                                </DropdownMenuItem>
                            </search-results>
                        </narrative-element-search>
                    </DropdownMenuSubContent>
                </DropdownMenuPortal>
            </DropdownMenuSub>
        </DropdownMenuContent>
    </DropdownMenu>
</template>

<script lang="ts" setup>
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from '@/shared/components/ui/dropdown-menu'
import SearchBar from '@/shared/components/search/SearchBar.vue'
import NarrativeElementSearch from '@/shared/components/search/NarrativeElementSearch.vue'
import NarrativeElementMiniCard from '@/shared/components/cards/NarrativeElementMiniCard.vue'
import SearchIcon from '@/shared/components/icons/SearchIcon.vue'
import { DropdownMenuPortal } from 'radix-vue'
import PlusDiamondIcon from '@/shared/components/icons/PlusDiamondIcon.vue'
import PlusIcon from '@/shared/components/icons/PlusIcon.vue'
import SearchResults from '@/shared/components/search/SearchResults.vue'
import { useRouter } from 'vue-router'
import api from '@/shared/utils/api'

const emit = defineEmits(['refresh'])

const props = defineProps({
    projectSlug: {
        type: String,
        default: () => undefined,
    },
    parentSlug: {
        type: String,
        default: () => undefined,
    },
    modelPlural: {
        type: String,
        default: () => undefined,
    },
    exclude: {
        type: Array,
        default: () => [],
    },
})

const router = useRouter()

function create() {
    router.push({
        name: 'conversation',
        params: {
            project_slug: props.projectSlug,
        },
        query: {
            modelName: props.modelPlural,
            project_slug: props.projectSlug,
            parentSlug: props.parentSlug,
        },
    })
}

function attach(targetSlug: string) {
    api.post(
        `/api/v1/projects/${props.projectSlug}/elements/${props.parentSlug}/connections`,
        {
            slug: targetSlug,
        }
    )

    emit('refresh')
}
</script>

<style scoped></style>
