<template>
    <div class="dynamiceSection relative flex w-full flex-col items-center">
        <title-section
            :title="section.title"
            :link="section?.link ?? undefined"
            :link-text="section?.link_text ?? undefined"
            :add-button="section?.add_button ?? undefined"
            @reload="emit('reload')"
            class="flex w-full max-w-prose flex-col gap-1 px-2 text-base xl:px-0 py-4 md:py-6 lg:py-10"
        >
            <div
                :class="getGapClasses"
                class="dynamicSectionContent flex w-full flex-col"
            >
                <v-button
                    v-if="editMode?.active"
                    class="self-start"
                    @click="addComponent(1)"
                >
                    Add first component in {{ section.title }}
                </v-button>
                <template
                    v-for="(component, index) in section.components"
                    :key="'component-' + component.slug"
                >
                    <dynamic-component @reload="emit('reload')" :content-component="component" />
                    <v-button
                        v-if="editMode?.active"
                        class="self-start"
                        @click="addComponent(index + 2)"
                    >
                        Add component after {{ component.title }}
                    </v-button>
                </template>
            </div>

            <!-- Render child sections -->
            <template v-if="section.children && section.children.length">
                <dynamic-section
                    @reload="emit('reload')"
                    v-for="childSection in section.children"
                    :key="'section-' + childSection.slug"
                    :section="childSection"
                />
            </template>
        </title-section>
        <button
            v-if="editMode?.active"
            class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
        >
            <trash-icon class="h-4 w-4" />
        </button>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, PropType } from 'vue'
import { Section } from '@/shared/types/page'
import TitleSection from '@/shared/components/TitleSection.vue'
import DynamicComponent from '@/shared/components/dynamic/DynamicComponent.vue'
import { editModeInjection } from '@/shared/types/injection'
import VButton from '@/shared/components/VButton.vue'
import api from '@/shared/utils/api'
import useModal from '@/shared/composables/useModal'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'

const emit = defineEmits(['reload'])

const props = defineProps({
    section: {
        type: Object as PropType<Section>,
        required: true,
    },
})

const modal = useModal()

const editMode = inject(editModeInjection)

const getGapClasses = computed(() => {
    return props.section.gap_classes
        ? props.section.gap_classes
        : ' gap-4 md:gap-6 lg:gap-8 '
})

function addComponent(index: number) {
    modal.show(
        'form',
        {
            fields: [
                {
                    name: 'display_name',
                    label: 'Section Name',
                    type: 'text',
                    required: true,
                    default: 'Untitled',
                },
                {
                    name: 'target_type',
                    label: 'Component Type',
                    type: 'select',
                    required: true,
                    options: [
                        {
                            value: 'App\\Models\\Achievement',
                            label: 'Achievement',
                        },
                        { value: 'App\\Models\\Clarify', label: 'Clarify' },
                        {
                            value: 'App\\Models\\Meta\\Audience',
                            label: 'Audience',
                        },
                        {
                            value: 'App\\Models\\Meta\\ReferenceStory',
                            label: 'Reference Story',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Character',
                            label: 'Character',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Moment',
                            label: 'Moment',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Plot',
                            label: 'Plot',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Sequence',
                            label: 'Sequence',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Setting',
                            label: 'Setting',
                        },
                        {
                            value: 'App\\Models\\Narrative\\Theme',
                            label: 'Theme',
                        },
                        { value: 'App\\Models\\Progress', label: 'Progress' },
                        {
                            value: 'App\\Models\\Project\\Season',
                            label: 'Season',
                        },
                        {
                            value: 'App\\Models\\Project\\Series',
                            label: 'Series',
                        },
                        {
                            value: 'App\\Models\\Project\\Story',
                            label: 'Story',
                        },
                        { value: 'App\\Models\\Recap', label: 'Recap' },
                        {
                            value: 'App\\Models\\Transcript',
                            label: 'Transcript',
                        },
                        { value: 'App\\Models\\User', label: 'User' },
                    ],
                    default: 'App\\Models\\Project\\Story',
                },
                {
                    name: 'component_name',
                    label: 'Component Name',
                    type: 'select',
                    required: true,
                    options: [
                        { value: 'AchievementCard', label: 'Achievement Card' },
                        { value: 'ArticleBlock', label: 'Article Block' },
                        {
                            value: 'BrainstormAchievementCard',
                            label: 'Brainstorm Achievement Card',
                        },
                        {
                            value: 'ClarificationCard',
                            label: 'Clarification Card',
                        },
                        { value: 'HeaderCaption', label: 'Header Caption' },
                        {
                            value: 'KeyValuePairDisplay',
                            label: 'Key Value Pair Display',
                        },
                        { value: 'ListBlock', label: 'List Block' },
                        { value: 'MomentCard', label: 'Moment Card' },
                        {
                            value: 'NarrativeElementCard',
                            label: 'Narrative Element Card',
                        },
                        {
                            value: 'SentimentContentBlock',
                            label: 'Sentiment Content Block',
                        },
                        {
                            value: 'StandardContentBlock',
                            label: 'Standard Content Block',
                        },
                        { value: 'TranscriptCard', label: 'Transcript Card' },
                    ],
                    default: 'StandardContentBlock',
                },
            ],
        },
        {
            onConfirm: (payload, close) => {
                const [data] = payload
                api.post(`/api/v1/sections/${props.section.id}/components`, {
                    order: index,
                    ...data,
                }).then(() => {
                    emit('reload')
                })
            },
        }
    )
}
</script>

<style scoped></style>
