<template>
    <article
        class="flex w-full max-w-lg flex-col justify-center gap-1 rounded-xl bg-stone-100 p-4 text-sm"
    >
        <header class="relative flex flex-col justify-center gap-0">
            <h2
                v-if="headerTitle"
                class="text-4xs font-bold uppercase text-stone-500 md:text-3xs"
            >
                {{ headerTitle }}
            </h2>
            <template v-if="title?.loading">
                <p class="animate-skeleton w-1/3">Loading</p>
            </template>
            <summary-block
                is="h3"
                v-else-if="title"
                :user-summary-content="title?.title"
                :user-summary-id="title?.id"
                class="text-ellipsis text-sm font-semibold text-stone-900 md:text-base"
                hover-class="hover:bg-stone-200"
            >
                {{ title?.title }}
            </summary-block>
            <v-button
                v-if="editMode?.active && !title"
                class="self-start"
                @click.stop="emit('connect-summary', 1)"
            >
                Connect title summary
            </v-button>
            <button
                v-if="title && editMode?.active"
                class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                @click.stop="emit('delete', title.id)"
            >
                <trash-icon class="h-4 w-4" />
            </button>
        </header>
        <template v-if="description?.loading">
            <p class="animate-skeleton w-2/3">Loading summary...</p>
            <p class="animate-skeleton mt-1 w-1/3">Loading summary...</p>
        </template>
        <summary-block
            is="div"
            v-else-if="description"
            :content-with-slugs="description?.contentWithSlugs"
            :mentions="description?.mentions"
            :user-summary-content="description?.text"
            :user-summary-id="description?.id"
            class="relative text-sm text-stone-700 md:text-base"
        >
            <summary-block-mentions-renderer
                :content-with-slugs="description?.contentWithSlugs"
                :mentions="description?.mentions"
                :user-summary-content="description?.text"
            />
            <v-button
                v-if="editMode?.active && !description"
                class="self-start"
                @click.stop="emit('connect-summary', 1)"
            >
                Connect description summary
            </v-button>
            <button
                v-if="description && editMode?.active"
                class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                @click.stop="emit('delete', description.id)"
            >
                <trash-icon class="h-4 w-4" />
            </button>
        </summary-block>
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click.stop="emit('connect-summary', 1)"
        >
            Connect first dialogue
        </v-button>
        <section
            v-if="dialogues?.length"
            class="mt-1 flex flex-col border-t border-solid border-stone-300 text-xs leading-4 text-stone-700"
        >
            <summary-block
                is="div"
                v-for="(dialogue, index) in dialogues"
                :key="index"
                :user-summary-content="dialogue.quote ?? dialogue.response"
                :user-summary-id="dialogue.id"
                class="relative flex flex-col border-b border-solid border-stone-300 py-2"
            >
                <p>{{ dialogue.speaker }}: "{{ dialogue.quote }}"</p>
                <p class="mt-2">
                    {{ dialogue.response.speaker }}: "{{
                        dialogue.response.quote
                    }}"
                </p>
                <v-button
                    v-if="editMode?.active"
                    class="self-start"
                    @click.stop="emit('connect-summary', 1)"
                >
                    Connect first dialogue
                </v-button>
                <button
                    v-if="description && editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', dialogue.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
        </section>
        <footer
            v-if="
                visualTitle ||
                visualDescription ||
                emotionalTitle ||
                emotionalDescription
            "
            class="mt-1 flex flex-col pl-3 text-xs font-medium leading-4 text-stone-800"
        >
            <h4
                v-if="visualTitle"
                class="text-ellipsis"
            >
                {{ visualTitle }}
            </h4>
            <p
                v-if="visualDescription"
                class="font-normal leading-4 text-stone-700"
            >
                {{ visualDescription }}
            </p>
            <h4
                v-if="emotionalTitle"
                class="mt-2 text-ellipsis"
            >
                {{ emotionalTitle }}
            </h4>
            <p
                v-if="emotionalDescription"
                class="font-normal text-stone-700"
            >
                {{ emotionalDescription }}
            </p>
        </footer>
    </article>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { editModeInjection } from '@/shared/types/injection'
import VButton from '@/shared/components/VButton.vue'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import SummaryBlock from '@/shared/components/SummaryBlock.vue'
import SummaryBlockMentionsRenderer from '@/shared/components/SummaryBlockMentionsRenderer.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    card: {
        type: Object,
        default: null,
    },
    summaries: {
        type: Array,
        default: () => [],
    },
})

const editMode = inject(editModeInjection)

const headerTitle = computed(() => {
    if (props.card) return props.card.headerTitle
    return 'Favorite Moment'
})

const title = computed(() => {
    if (props.card) return props.card.title
    return props.summaries.find((summary) => summary.type === 'title')
})

const description = computed(() => {
    if (props.card) return props.card.description
    return props.summaries.find((summary) => summary.type === 'paragraph')
})

const dialogues = computed(() => {
    if (props.card) return props.card.dialogues
    return props.summaries
        .filter((summary) => summary.type === 'dialogue')
        .map((summary) => ({
            id: summary.id,
            speaker: summary.speaker,
            quote: summary.text,
            response:
                summary.dialogueLines?.map((line) => ({
                    speaker: line.speaker,
                    quote: line.quote,
                })) ?? '',
        }))
})

const visualTitle = computed(() => {
    if (props.card && props.card.visual) return props.card.visual.title
    return null
})

const visualDescription = computed(() => {
    if (props.card && props.card.visual) return props.card.visual.description
    return null
})

const emotionalTitle = computed(() => {
    if (props.card && props.card.emotional) return props.card.emotional.title
    return null
})

const emotionalDescription = computed(() => {
    if (props.card && props.card.emotional)
        return props.card.emotional.description
    return null
})
</script>

<style scoped></style>
