<template>
    <div class="flex flex-col mt-1">
        <template v-if="isLoading">
            <div
                v-for="i in 5"
                :key="i"
                class="animate-skeleton mt-1"
            />
        </template>
        <div v-else-if="!results?.length">
            <p class="text-stone-400">No results found</p>
        </div>
        <template v-else>
            <template
                v-for="result in results"
                :key="result.id"
            >
                <slot :result="result" />
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'

const results = inject('results')
const isLoading = inject('isLoading')
</script>

<style scoped></style>
