<!-- ListWrapper.vue -->
<template>
    <div class="flex w-full flex-col items-center text-base">
        <div class="flex w-full flex-col gap-3">
            <div class="flex w-full flex-col gap-2">
                <div
                    class="flex w-full flex-row items-center gap-0 overflow-hidden"
                >
                    <h3 class="select-none text-xl font-medium text-stone-800">
                        {{ title.charAt(0).toUpperCase() + title.slice(1) }}
                    </h3>
                    <slot name="button">
                        <button
                            class="cursor-pointer select-none px-1 py-2 text-xs text-stone-400 hover:text-stone-900"
                            @click="emit('create')"
                        >
                            + Create
                        </button>
                    </slot>
                </div>
                <div
                    class="flex w-full flex-row items-stretch justify-between gap-2 overflow-hidden"
                >
                    <div class="w-full">
                        <div
                            v-if="true"
                            class="relative"
                        >
                            <input
                                :placeholder="placeholder"
                                :value="searchValue"
                                class="min-h-10 w-full rounded-lg bg-stone-50 py-2.5 pl-3 pr-10 text-lg text-stone-400 outline-0"
                                @input="
                                    ($event) => {
                                        emit(
                                            'update:searchValue',
                                            $event.target.value
                                        )
                                    }
                                "
                            />
                            <button class="absolute right-3 top-3 pt-1">
                                <search-icon
                                    v-if="!searchValue?.length"
                                    class="h-4 text-gray-500"
                                />
                                <x-mark-icon
                                    v-else
                                    class="h-4 text-gray-500"
                                    @click="emit('update:searchValue', '')"
                                />
                            </button>
                        </div>
                    </div>
                    <!-- <div
                        class="relative flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded bg-stone-50 text-stone-400 hover:bg-stone-400 hover:text-stone-50"
                    >
                        <filter-icon
                            @click="toggleFilter"
                            class="h-5 w-5"
                        />
                    </div> -->
                    <detail-level-switcher
                        :model-value="isCondensed"
                        class="relative flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded"
                        @update:modelValue="toggleIsCondensed"
                    />
                </div>
            </div>
            <!--            <div class="flex w-full items-center justify-between">-->
            <!--                <h1 class="text-lg font-bold text-black">{{ title }}</h1>-->
            <!--                <div class="flex items-center gap-3">-->
            <!--                    <button @click="showSearch = !showSearch">-->
            <!--                        <search-icon-->
            <!--                            :class="-->
            <!--                                showSearch-->
            <!--                                    ? 'text-orange-600'-->
            <!--                                    : 'text-slate-500'-->
            <!--                            "-->
            <!--                        />-->
            <!--                    </button>-->
            <!--                    <button-->
            <!--                        v-if="enableFilter"-->
            <!--                        @click="showModal = true"-->
            <!--                    >-->
            <!--                        <filter-icon class="text-slate-400" />-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="h-px w-full bg-stone-200" />
            <div class="flex w-full flex-col items-center gap-2 lg:gap-3">
                <slot
                    :isCondensed="isCondensed"
                    name="content"
                />
            </div>
        </div>
    </div>

    <modal-bottom
        v-if="showModal"
        class="p-2"
        container-class="rounded-3xl bg-pure-white px-6 pb-6 relative w-full flex flex-col gap-6"
        @close="showModal = false"
    >
        <template #content>
            <slot name="modal" />
        </template>
        <template #closeButton1>
            <slot name="closeButton1" />
        </template>
        <template #closeButton2>
            <slot name="closeButton2" />
        </template>
    </modal-bottom>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ModalBottom from '@/shared/components/ModalBottom.vue'

import XMarkIcon from '@/shared/components/icons/XmarkIcon.vue'
import SearchIcon from '@/shared/components/icons/SearchIcon.vue'
import DetailLevelSwitcher from '@/shared/components/ui/DetailLevelSwitcher.vue'

const emit = defineEmits(['update:searchValue', 'update:isCondensed', 'create'])

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    enableFilter: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Search',
    },
    searchValue: {
        type: String,
        required: true,
    },
    isCondensed: {
        type: Boolean,
        required: true,
    },
})

const showSearch = ref(false)

const showModal = ref(false)

const toggleFilter = () => {}

const toggleIsCondensed = () => {
    emit('update:isCondensed', !props.isCondensed)
}
</script>

<style scoped></style>
