<template>
    <div class="flex grow items-center justify-center">
        <div
            class="flex w-full max-w-80 flex-col gap-5 rounded-xl bg-white p-5 shadow-xl"
        >
            <h1
                class="w-full select-none font-main text-lg font-medium text-stone-950"
            >
                Finish setting up your account
            </h1>
            <div class="flex w-full flex-col gap-6">
                <label
                    class="w-full text-xs font-semibold uppercase text-stone-300"
                    for="first_name"
                >
                    First Name
                    <input
                        id="first_name"
                        v-model="data.first_name"
                        autocomplete="given-name"
                        class="block w-full border-b border-stone-400 bg-white py-3 text-base font-normal text-stone-950 placeholder:text-stone-400 focus:outline-none"
                        name="first_name"
                        placeholder="Enter your first name"
                        required
                        type="text"
                    />
                </label>
                <label
                    class="w-full text-xs font-semibold uppercase text-stone-300"
                    for="last_name"
                >
                    Last Name
                    <input
                        id="last_name"
                        v-model="data.last_name"
                        autocomplete="family-name"
                        class="block w-full border-b border-stone-400 bg-white py-3 text-base font-normal text-stone-950 placeholder:text-stone-400 focus:outline-none"
                        name="last_name"
                        placeholder="Enter your last name"
                        type="text"
                    />
                </label>
                <label for="receive_emails" class="flex items-center">
                    <input
                        id="receive_emails"
                        v-model="data.receive_emails"
                        class="mr-2"
                        name="receive_emails"
                        type="checkbox"
                    />
                    <span class="text-stone-400 text-xs">
                        Receive emails about new features and updates
                    </span>
                </label>
            </div>
            <v-button @click="save">Save</v-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import VButton from '@/shared/components/VButton.vue'
import { updateName } from '@/shared/utils/endpoints'
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'

const emit = defineEmits(['close'])
const router = useRouter()
const data = reactive({
    first_name: '',
    last_name: '',
    receive_emails: true,
})

const queryClient = useQueryClient()

function save(promise: (promise: () => Promise<void>) => void) {
    promise(async () => {
        const response = await updateName({
            first_name: data.first_name,
            last_name: data.last_name,
            receive_emails: data.receive_emails,
        })
        void queryClient.invalidateQueries({
            queryKey: ['user'],
        })

        if (response.data.onboarded) {
            void router.push({ name: 'home' })
        } else {
            void router.push({ name: 'home' })
        }
        emit('close')
    })
}
</script>

<style scoped></style>
