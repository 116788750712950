<template>
    <div
        :class="{
            'max-w-md': !isFullWidth,
        }"
        class="mx-auto flex h-full w-full items-end md:items-center md:p-4"
        @click.self="emit('close')"
    >
        <!-- todo: @mitch restyle this popup (small box on bottom-center of screen) -->
        <div
            @click.stop
            ref="container"
            :style="{
                transform: offset ? `translateY(${offset}px)` : undefined,
                height: expanded
                            ? `min(100% - 100px, ${maxHeight}px)`
                            : currentHeight
                              ? `${currentHeight}px`
                              : undefined,
            }"
            class="relative m-2 h-full flex w-full overflow-hidden flex-col rounded-xl bg-white pb-5 shadow-2xl transition-all md:py-5"
        >
            <button
                class="sticky top-0 z-10 flex w-full justify-center bg-white py-1 md:hidden"
                @touchstart.passive="onTouchDragStart"
                @touchend.passive="onDragEnd"
                @touchmove.passive="onTouchDrag"
                @mousedown.passive="onClickDragStart"
                @mouseup.passive="onDragEnd"
            >
                <span class="h-1.5 w-1/6 rounded-full bg-gray-300"></span>
            </button>
            <div class="flex grow flex-col gap-10 px-6 overflow-hidden">
                <div
                    ref="content"
                    class="transform-gpu flex grow flex-col overflow-scroll transition-all"
                    @scroll.passive="onScroll"
                >
                    <slot />
                </div>
                <div
                    v-if="primary || secondary"
                    class="flex bg-white flex-col items-stretch justify-center gap-3 md:flex-row-reverse"
                >
                    <button
                        v-if="primary"
                        class="grow rounded-lg bg-stone-700 py-3 text-base font-medium text-stone-50 hover:bg-stone-900"
                        @click="emit('primary')"
                    >
                        {{ primary }}
                    </button>
                    <div
                        v-if="secondary"
                        class="flex grow cursor-pointer items-center justify-center text-sm font-medium text-stone-600 hover:text-stone-900"
                        @click="emit('secondary')"
                    >
                        {{ secondary }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue'
import { animate } from 'motion'

const emit = defineEmits(['close', 'primary', 'secondary'])

const props = defineProps({
    primary: {
        type: String,
        default: () => undefined,
    },
    secondary: {
        type: String,
        default: () => undefined,
    },
    isFullWidth: {
        type: Boolean,
        default: false,
    },
})

const close = inject('close-current-modal') as () => void

const offset = ref(0)
const touchStart = ref<number | undefined>(0)
const container = ref<HTMLDivElement | null>(null)
const content = ref<HTMLDivElement | null>(null)
const expanded = ref(false)
const maxHeight = ref(undefined)
const currentHeight = ref(undefined)

function onScroll(e: Event) {
    if (!content.value) return
    if (expanded.value) return
    expanded.value = content.value.scrollTop > 10
}

function onDragStart(y: number) {
    touchStart.value = y
}

function onTouchDragStart(e: TouchEvent) {
    onDragStart(e.touches[0].clientY)
}

function onClickDragStart(e: MouseEvent) {
    onDragStart(e.clientY)
    document.addEventListener('mousemove', onClickDrag)
}

function onDrag(y: number) {
    if (touchStart.value === undefined) return
    const diff = y - touchStart.value
    // apply a function to the diff to make the modal move slower
    offset.value = Math.abs(diff) - Math.abs(diff) / 2
    offset.value *= diff > 0 ? 1 : -1
}

function onTouchDrag(e: TouchEvent) {
    onDrag(e.touches[0].clientY)
}

function onClickDrag(e: MouseEvent) {
    if (e.buttons !== 1) return
    onDrag(e.clientY)
}

function onDragEnd() {
    if (offset.value > 50) {
        close()
    } else {
        if (!container.value) return
        animate(
            container.value,
            {
                transform: [`translateY(${offset.value}px)`, 'translateY(0px)'],
            },
            { duration: 0.3 }
        ).finished.then(() => {
            offset.value = 0
        })
    }
    touchStart.value = undefined
    document.removeEventListener('mousemove', onClickDrag)
}

onMounted(() => {
    if (!container.value) return
    maxHeight.value = container.value.clientHeight
    currentHeight.value = Math.min(window.innerHeight / 2, maxHeight.value)
})
</script>

<style scoped></style>
