<template>
    <DropdownMenu>
        <DropdownMenuTrigger
            :class="props.class"
            class="shrink-0 p-1"
        >
            <more-icon class="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuItem
                v-if="isEnabled('edit')"
                class="gap-2"
                @click="emit('edit')"
            >
                <edit-icon class="h-4 w-4" />
                Edit
            </DropdownMenuItem>
            <DropdownMenuItem
                v-if="isEnabled('upload')"
                class="gap-2"
                @click="emit('upload')"
            >
                <image-icon class="h-4 w-4" />
                Images
            </DropdownMenuItem>
            <DropdownMenuItem
                v-if="isEnabled('delete')"
                class="gap-2"
                @click="emit('delete')"
            >
                <trash-icon class="h-4 w-4" />
                Delete
            </DropdownMenuItem>
            <DropdownMenuItem
                v-if="isEnabled('deleteConnection')"
                class="gap-2"
                @click="emit('deleteConnection')"
            >
                <unlink-icon class="h-4 w-4" />
                Remove
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>

<script lang="ts" setup>
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/shared/components/ui/dropdown-menu'
import EditIcon from '@/shared/components/icons/EditIcon.vue'
import MoreIcon from '@/shared/components/icons/MoreIcon.vue'
import ImageIcon from '@/shared/components/icons/ImageIcon.vue'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import { PropType, toValue } from 'vue'
import { ConditionalProps } from '@/shared/lib/utils'
import UnlinkIcon from '@/shared/components/icons/UnlinkIcon.vue'

const emit = defineEmits(['edit', 'delete', 'upload', 'deleteConnection'])

const props = defineProps({
    class: {
        type: [String, Object, Array],
        default: '',
    },
    hideButtons: {
        type: [String, Array, Object] as PropType<ConditionalProps<ButtonType>>,
        default: () => undefined,
    },
})

export type ButtonType = 'edit' | 'delete' | 'upload' | 'deleteConnection'

function isEnabled(button: ButtonType) {
    if (!props.hideButtons) {
        return true
    }

    // if string
    if (typeof props.hideButtons === 'string') {
        return props.hideButtons !== button
    }

    // if array
    if (Array.isArray(props.hideButtons)) {
        return !props.hideButtons.includes(button)
    }

    // if object
    if (props.hideButtons[button]) {
        return !toValue(props.hideButtons[button])
    }

    return true
}
</script>

<style scoped></style>
