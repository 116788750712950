<template>
    <svg
        fill="none"
        viewBox="0 0 3 13"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clip-rule="evenodd"
            d="M1.75 -5.46392e-08C1.05964 -8.48156e-08 0.5 0.559644 0.5 1.25C0.5 1.94036 1.05964 2.5 1.75 2.5C2.44036 2.5 3 1.94036 3 1.25C3 0.559644 2.44036 -2.44628e-08 1.75 -5.46392e-08ZM0.5 6.25C0.5 5.55964 1.05964 5 1.75 5C2.44036 5 3 5.55964 3 6.25C3 6.94036 2.44036 7.5 1.75 7.5C1.05964 7.5 0.5 6.94036 0.5 6.25ZM0.5 11.25C0.5 10.5596 1.05964 10 1.75 10C2.44036 10 3 10.5596 3 11.25C3 11.9404 2.44036 12.5 1.75 12.5C1.05964 12.5 0.499999 11.9404 0.5 11.25Z"
            fill="currentColor"
            fill-rule="evenodd"
        />
    </svg>
</template>
