<template>
    <div class="relative w-full bg-stone-100">
        <search-icon
            v-if="!searchValue?.length"
            class="absolute left-0 top-1/2 h-2/3 -translate-y-1/2 transform text-stone-500"
        />
        <input
            v-model="searchValue"
            :class="{
                'pl-5': !searchValue?.length,
            }"
            class="w-full rounded-sm"
            placeholder="Search..."
            type="text"
        />
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import SearchIcon from '@/shared/components/icons/SearchIcon.vue'

const searchValue = inject('searchValue')
</script>

<style scoped></style>
