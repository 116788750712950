<template>
    <component
        :is="element?.path && !withoutLink ? 'router-link' : 'div'"
        :to="computedPath"
        class="narrative-element-card w-full"
    >
        <div
            v-if="element"
            :class="[
                getCardClasses,
                {
                    'hover:border-stone-200 hover:shadow': element.path,
                },
            ]"
            class="flex flex-col gap-2 rounded-lg border border-solid border-transparent p-3"
        >
            <div
                class="narrative-element-card-header flex flex-row items-center gap-2 overflow-hidden"
            >
                <div
                    :class="{
                        'handle cursor-move': draggable,
                    }"
                    class="flex"
                >
                    <transition
                        enter-active-class="transition-all ease-in-out duration-300"
                        enter-from-class="opacity-0 w-0"
                        enter-to-class="opacity-100 w-5"
                        leave-active-class="transition-all ease-in-out duration-300"
                        leave-from-class="opacity-100 w-5"
                        leave-to-class="opacity-0 w-0"
                    >
                        <button
                            v-if="draggable"
                            class="cursor-move text-gray-400 hover:text-gray-600 duration-75"
                        >
                            <grip-vertical-icon class="h-5 w-5" />
                        </button>
                    </transition>
                    <div class="relative h-11 w-11 overflow-hidden rounded">
                        <image-component
                            v-if="
                                (element.image && element.image?.small) ||
                                !element.display_order
                            "
                            :alt="element.title"
                            :fallback-image-type="
                                [
                                    'stories',
                                    'series',
                                    'project',
                                    'story',
                                    'season',
                                ].includes(element.model_plural ?? element.type)
                                    ? 'film'
                                    : ['characters', 'character'].includes(
                                            element.model_plural ?? element.type
                                        )
                                      ? 'character'
                                      : undefined
                            "
                            :image="element.image"
                            class="h-full w-full flex-shrink-0 rounded object-cover"
                            disable-click
                            size="thumbnail"
                        />
                        <transition
                            enter-active-class="transition-opacity duration-300"
                            enter-from-class="opacity-0"
                            leave-active-class="transition-opacity duration-300"
                            leave-to-class="opacity-0"
                        >
                            <div
                                v-if="draggable && order !== undefined"
                                :class="[
                                    element.image && element.image?.small
                                        ? 'bg-stone-400/50'
                                        : 'bg-stone-400',
                                ]"
                                class="absolute inset-0 flex flex-shrink-0 select-none items-center justify-center object-cover text-xs text-white"
                            >
                                {{ order?.toString().padStart(2, '0') }}
                            </div>
                        </transition>
                    </div>
                </div>

                <div
                    class="flex grow select-none flex-col gap-0 self-center overflow-hidden"
                >
                    <div
                        v-if="
                            element.tags?.length ||
                            $slots.tags ||
                            !!props.tags?.length
                        "
                        :class="getTagsClasses"
                        class="items-center gap-1 truncate text-4xs font-bold uppercase leading-tight"
                    >
                        <slot name="tags">
                            {{ getTags }}
                        </slot>
                    </div>
                    <h5
                        :class="getTitleClass"
                        :title="element.title"
                        class="line-clamp-1 font-bold"
                    >
                        {{ element.title }}
                    </h5>

                    <div
                        v-if="
                            element.subtitles?.length ||
                            $slots.subtitles ||
                            !!props.subtitles?.length
                        "
                        class="truncate text-3xs font-medium text-stone-600"
                    >
                        <slot name="subtitles">
                            {{ getSubtitles }}
                        </slot>
                    </div>
                </div>

                <flame-icon
                    v-if="element.progress !== null"
                    :progress="element.progress"
                    class="!h-8 !w-8 flex-shrink-0"
                    flameClass="w-6 h-6"
                />

                <slot name="dropdown">
                    <narrative-element-dropdown-menu
                        v-if="showDropdown"
                        :hide-buttons="hideButtons"
                        class="rounded border border-transparent text-stone-600 hover:border-stone-300 hover:text-stone-900"
                        @delete="emit('delete')"
                        @edit="emit('edit')"
                        @delete-connection="emit('deleteConnection')"
                    />
                </slot>

                <!--                <DropdownMenu>-->
                <!--                    <DropdownMenuTrigger>-->
                <!--                        <more-icon class="h-4 w-4" />-->
                <!--                    </DropdownMenuTrigger>-->
                <!--                </DropdownMenu>-->
            </div>

            <div
                v-if="!isCondensed"
                class="narrative-element-card-content flex w-full flex-col gap-2"
            >
                <hr
                    v-if="showLine && element.paragraph"
                    class="w-full text-stone-500"
                />

                <p
                    v-if="element.paragraph"
                    :class="getParagraphClasses"
                    class="select-none text-sm"
                >
                    <summary-block-mentions-renderer
                        :content-with-slugs="element.paragraph.contentWithSlugs"
                        :mentions="element.paragraph.mentions"
                        :user-summary-content="element.paragraph.summary"
                    />
                </p>

                <div
                    v-if="element.additional_data"
                    class="additional-data"
                >
                    <!-- Render additional data based on element type -->
                    <template
                        v-if="
                            element.type === 'audience' &&
                            element.additional_data.prefer &&
                            element.additional_data.dislike
                        "
                    >
                        <p
                            class="mt-2 flex items-start gap-2 text-sm font-normal text-stone-800"
                        >
                            <thumbs-icon
                                class="h-6 w-6 shrink-0 rounded-full bg-white p-0.5 text-blue-500"
                            />
                            {{ element.additional_data.prefer }}
                        </p>
                        <p
                            class="mt-2 flex items-start gap-2 text-sm font-normal text-stone-800"
                        >
                            <thumbs-icon
                                class="h-6 min-w-6 shrink-0 rotate-180 rounded-full bg-white p-0.5 text-red-600"
                            />
                            {{ element.additional_data.dislike }}
                        </p>
                    </template>
                    <!-- Add more type-specific rendering as needed -->
                </div>
            </div>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType } from 'vue'
import FlameIcon from '@/shared/components/FlameInProgressCircle.vue'
import ImageComponent from '@/shared/components/ImageComponent.vue'
import ThumbsIcon from '@/shared/components/icons/ThumbsIcon.vue'
import { useRoute } from 'vue-router'
import SummaryBlockMentionsRenderer from '@/shared/components/SummaryBlockMentionsRenderer.vue'
import NarrativeElementDropdownMenu, {
    ButtonType,
} from '@/shared/components/ui/NarrativeElementDropdownMenu.vue'
import { ConditionalProps } from '@/shared/lib/utils'
import GripVerticalIcon from '@/shared/components/icons/GripVerticalIcon.vue'

const emit = defineEmits(['upload', 'edit', 'delete', 'deleteConnection'])

interface NarrativeElement {
    type: string
    display_order?: number
    image?: {
        small: string
        medium: string
        large: string
    }
    model_plural: string
    slug: string
    tags: string[]
    title: string
    titleClass?: string
    subtitles: string[]
    progress?: number
    paragraph?: string
    path: string
    additional_data?: Record<string, any>
}

const props = defineProps({
    element: {
        type: Object as PropType<NarrativeElement>,
        required: false,
    },
    showLine: {
        type: Boolean,
        default: true,
    },
    isCondensed: {
        type: Boolean,
        default: true,
    },
    selected: {
        type: Boolean,
        default: false,
    },
    withoutLink: {
        type: Boolean,
        default: false,
    },
    showDropdown: {
        type: Boolean,
        default: false,
    },
    hideButtons: {
        type: [String, Array, Object] as PropType<ConditionalProps<ButtonType>>,
        default: () => null,
    },
    draggable: {
        type: Boolean,
        default: false,
    },
    order: {
        type: Number,
        default: () => undefined,
    },
    tags: {
        type: Array,
        default: () => [],
    },
    subtitles: {
        type: Array,
        default: () => [],
    },
})

const route = useRoute()

// const isCondensed = computed(() => {
//     return props.setIsCondensed
// })

const getTags = computed(() => {
    return (props.tags.length ? props.tags : props.element?.tags)
        ?.slice()
        .join(' • ')
})

const getSubtitles = computed(() => {
    return (props.subtitles.length ? props.subtitles : props.element?.subtitles)
        ?.slice()
        .join(' • ')
})

const getTitleClass = computed(() => {
    const baseClasses = []
    const colorClass = props.selected ? 'text-stone-50' : 'text-stone-800'

    if (!props.element) {
        return baseClasses.join(' ')
    }

    // Add the color class
    baseClasses.push(colorClass)

    // Check for element.titleClass
    if (props.element?.titleClass) {
        // Split the titleClass string into an array of classes
        const titleClasses = props.element.titleClass.split(' ')

        // Filter out any color classes from element.titleClass
        const nonColorClasses = titleClasses.filter(
            (cls) => !cls.startsWith('text-')
        )

        // Add the non-color classes to baseClasses
        baseClasses.push(...nonColorClasses)
    } else {
        // If no titleClass, add classes based on element type
        if (
            ['series', 'project', 'season', 'story'].includes(
                props.element.type
            )
        ) {
            baseClasses.push('font-fjalla', 'text-lg')
        }
    }

    // Join all classes into a single string
    return baseClasses.join(' ')
})

const computedPath = computed(() => {
    const narrativePath = props.element?.path

    if (!narrativePath) {
        return null
    }

    if (
        route.path.startsWith('/projects') &&
        route.path.split('/').length > 2
    ) {
        // 1. If the current route path starts with /projects/project_slug, then add that in front of the narrative path
        const projectSlug = route.path.split('/')[2]
        return `/projects/${projectSlug}${narrativePath}`
    }

    // 2. Otherwise, just use the narrative path
    return `${narrativePath}`
})

const getCardClasses = computed(() => {
    if (props.selected) {
        return {
            'bg-stone-800': true,
        }
    }
    return {
        'bg-stone-50': true,
    }
})

const getTagsClasses = computed(() => {
    if (props.selected) {
        return {
            'text-stone-50': true,
        }
    }
    return {
        'text-stone-500': true,
    }
})

const getParagraphClasses = computed(() => {
    if (props.selected) {
        return {
            'text-stone-400': true,
        }
    }
    return {
        'text-stone-700': true,
    }
})

onMounted(() => {})
</script>

<style scoped>
/* Add any necessary styles */
</style>
