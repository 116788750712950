<template>
    <div class="flex w-full flex-col items-center gap-0 bg-stone-100">
<!--        <div-->
<!--            class="flex w-full select-none flex-col items-center bg-neutral-950 px-4 py-4 lg:py-8"-->
<!--        >-->
<!--            <div class="flex w-full max-w-prose flex-col items-start gap-0">-->
                <h2 class="text-base font-medium tracking-wide text-stone-700 pt-4 pb-2">
                    Your Development Dashboard
                </h2>
<!--                <div class="text-sm text-stone-400">-->
<!--                    Start developing your first story and come back here to-->
<!--                    track your progress.-->
<!--                </div>-->
<!--            </div>-->
            <!--            <div v-if="user" class="flex items-center justify-between gap-3">-->
            <!--                <achievement-summary-card-->
            <!--                    title="Earned"-->
            <!--                    :value="user.completed_achievements"-->
            <!--                    card-class="flex flex-col gap-2 p-2 rounded-lg bg-neutral-800 w-full max-w-28"-->
            <!--                    value-class="text-sm text-pure-white font-bold"-->
            <!--                />-->
            <!--                <achievement-summary-card-->
            <!--                    title="In Progress"-->
            <!--                    :value="user.progress_achievements"-->
            <!--                    card-class="flex flex-col gap-2 p-2 rounded-lg bg-neutral-800 w-full max-w-28"-->
            <!--                    value-class="text-sm text-pure-white font-bold"-->
            <!--                />-->
            <!--                <achievement-summary-card-->
            <!--                    title="Up Next"-->
            <!--                    :value="user.next_achievements"-->
            <!--                    card-class="flex flex-col gap-2 p-2 rounded-lg bg-neutral-800 w-full max-w-28"-->
            <!--                    value-class="text-sm text-pure-white font-bold"-->
            <!--                />-->
            <!--            </div>-->
<!--        </div>-->
        <session-chat-summary />
    </div>
</template>

<script lang="ts" setup>
import SessionChatSummary from '@/shared/components/SessionChatSummary.vue'
import { useUser } from '@/shared/composables/query/user'

const { data: user } = useUser()
</script>
