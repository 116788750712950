<!-- Namespace: shared.components.modals -->
<!-- Filename: ReconciliationPopup.vue -->
<template>
    <div
        class="fixed z-30 flex h-full w-full items-center justify-start md:justify-center md:p-4"
    >
        <div
            class="absolute inset-0 w-full"
            @click="$emit('close')"
        />
        <div
            class="relative flex w-full flex-col bg-pure-white h-full md:h-5/6 lg:h-60dvh md:max-w-screen-md md:flex-row md:rounded-xl overflow-hidden"
        >
            <button
                class="absolute left-auto right-2 top-2 select-none md:hidden"
            >
                <xmark-icon
                    class="h-6 w-6 shrink-0 cursor-pointer text-stone-300"
                    @click="$emit('close')"
                />
            </button>
            <div
                class="flex w-full flex-col gap-2 bg-stone-800 p-3 md:max-w-56 md:rounded-l-xl"
            >
                <h1 class="text-xs font-bold uppercase text-stone-150">
                    Reconciliations
                </h1>
                <div class="flex w-full gap-2 overflow-x-auto md:flex-col">
                    <button
                        v-for="reconciliation in reconciliations"
                        :key="reconciliation.slug"
                        class="select-none rounded px-3 py-2 text-base font-medium lg:w-full"
                        :class="
                            selectedReconciliationSlug === reconciliation.slug
                                ? 'bg-stone-150 text-stone-700 hover:bg-white'
                                : 'bg-stone-950 text-stone-50 hover:bg-stone-600'
                        "
                        @click="selectedReconciliationSlug = reconciliation.slug"
                    >
                        {{ reconciliation.project_name }}
                    </button>
                </div>
            </div>
            <div class="flex h-full w-full flex-col bg-white md:rounded-r-xl">
                <reconciliation-banner
                    :selected-reconciliation="selectedReconciliation"
                />
                <div
                    class="flex h-[calc(100%-221px)] w-full shrink-0 flex-col gap-2.5 overflow-y-auto p-3 md:h-[calc(100%-81px)]"
                >
                    <template v-if="editMode">
                        <div
                            class="flex w-full items-center justify-between gap-2"
                        >
                            <button
                                class="flex select-none items-center text-3xs font-bold text-stone-400"
                                @click="exitEditMode"
                            >
                                <chevron-icon class="h-4 w-4" />
                                Back
                            </button>

                            <button
                                v-if="canEdit"
                                class="flex select-none items-center text-3xs font-bold uppercase text-stone-400"
                            >
                                Edit mode
                            </button>
                        </div>
                        <reconciliation-card
                            v-if="selectedItem"
                            :element="selectedItem"
                            :is-edit-mode="editMode"
                            :can-edit="canEdit"
                            :reconciliation-slug="selectedReconciliationSlug"
                            @update:element="updateSelectedItem"
                            @update:is-edit-mode="updateEditMode"
                            @update:can-edit="updateCanEdit"
                        />
                        <p
                            v-else
                            class="flex flex-1 items-center justify-center text-stone-700"
                        >
                            Please select an item
                        </p>
                    </template>
                    <template v-else-if="selectedReconciliation?.update_items?.length">
                        <reconciliation-card
                            v-for="(
                                element, elementID
                            ) in selectedReconciliation.update_items"
                            :key="elementID"
                            :element="element"
                            :is-edit-mode="false"
                            :can-edit="isReconciliationProcessing"
                            :reconciliation-slug="selectedReconciliationSlug"
                            @update:is-edit-mode="goToEditMode(element)"
                            @update:can-edit="updateCanEdit"
                        />
                    </template>
                    <p
                        v-else
                        class="flex flex-1 items-center justify-center text-stone-700"
                    >
                        No items
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import XmarkIcon from '@/shared/components/icons/XmarkIcon.vue'
import ChevronIcon from '@/shared/components/icons/ChevronIcon.vue'
import ReconciliationCard from '@/shared/components/cards/ReconciliationCard.vue'
import ReconciliationBanner from '@/shared/components/banners/ReconciliationBanner.vue'
import { useReconciliationStore } from "@/shared/stores/reconciliations"
import { storeToRefs } from "pinia"

const reconciliationStore = useReconciliationStore()
const { reconciliations } = storeToRefs(reconciliationStore)

const emit = defineEmits(['close'])

const props = defineProps({
    announcement_type: String,
    title: String,
    subtitle: String,
    content: String,
})

const editMode = ref(false)
const canEdit = ref(false)
const selectedItem = ref(null)
const selectedReconciliationSlug = ref('')

// Initialize selectedReconciliationSlug when reconciliations are available
watch(() => reconciliations.value, (newReconciliations) => {
    if (newReconciliations && newReconciliations.length > 0) {
        selectedReconciliationSlug.value = newReconciliations[0].slug
    }
}, { immediate: true })

const selectedReconciliation = computed(() =>
    reconciliations.value?.find((reconciliation) => reconciliation.slug === selectedReconciliationSlug.value)
)

const isReconciliationProcessing = computed(() =>{
    return selectedReconciliation.value?.status === 'processing';
})

const exitEditMode = () => {
    editMode.value = false
    canEdit.value = false
    selectedItem.value = null
}

const goToEditMode = (element) => {
    selectedItem.value = element
    editMode.value = true
    canEdit.value = false
}

const updateSelectedItem = (updatedElement) => {
    if (!reconciliations.value) {
        console.error('Reconciliations data is not available')
        return
    }

    const reconciliation = reconciliations.value.find(
        (r) => r.slug === selectedReconciliationSlug.value
    )

    if (!reconciliation) {
        console.error('Selected reconciliation not found')
        return
    }

    if (!reconciliation.updated_items) {
        reconciliation.updated_items = []
    }

    const itemIndex = reconciliation.updated_items.findIndex(
        (item) => item.reconciliation_update_slug === updatedElement.reconciliation_update_slug
    )

    if (itemIndex === -1) {
        // If the item doesn't exist in the array, add it
        reconciliation.updated_items.push(updatedElement)
    } else {
        // If the item exists, update it
        reconciliation.updated_items[itemIndex] = updatedElement
    }

    selectedItem.value = updatedElement
}

const updateEditMode = (newEditMode) => {
    editMode.value = newEditMode
}

const updateCanEdit = (newCanEdit) => {
    canEdit.value = newCanEdit
}
</script>
