<template>
    <modal-popup
        :primary="primary"
        :secondary="secondary"
        @primary="emit('primary')"
        @secondary="emit('secondary')"
    >
        <div class="flex flex-col items-center gap-3 text-center">
            <div class="w-full flex flex-col gap-2 items-center justify-center bg-stone-400 rounded-lg py-4">
                <!-- Voice input silent error -->
                <voice-silent-icon
                    v-if="type === 'voice-silent'"
                    class="h-10 w-auto text-white"
                />
                <!-- Generic error -->
                <triangle-alert-icon
                    v-else
                    class="h-10 w-auto text-white"
                />
                <h3 class="text-xs uppercase font-semibold text-white tracking-wider">
                    {{ title }}
                </h3>
            </div>

            <div>
                <div class="text-base text-stone-800">{{ message }}</div>
            </div>
            <small
                v-if="details"
                class="p-2 text-xs bg-stone-100 rounded text-stone-600"
            >
                {{ details }}
            </small>
        </div>
    </modal-popup>
</template>

<script lang="ts" setup>
import ModalPopup from '@/shared/components/modals/ModalPopup.vue'
import { PropType } from 'vue'
import VoiceSilentIcon from '@/shared/components/icons/VoiceSilentIcon.vue'
import TriangleAlertIcon from '@/shared/components/icons/TriangleAlertIcon.vue'

const emit = defineEmits(['primary', 'secondary'])

const props = defineProps({
    type: {
        type: String as PropType<'voice-silent' | 'error'>,
        default: 'error',
    },
    title: {
        type: String,
        default: "We couldn't hear you. Please try again.",
    },
    message: {
        type: String,
        default: 'Make sure your microphone is connected and working properly.',
    },
    details: {
        type: String,
        default: () => undefined,
    },
    primary: {
        type: String,
        default: 'Continue',
    },
    secondary: {
        type: String,
        default: () => undefined,
    },
})
</script>

<style scoped></style>
