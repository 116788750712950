<template>
    <transition
        :class="{
            'bg-black/50': backdrop,
        }"
        :name="backdrop ? 'v-modal-backdrop' : 'v-modal-none'"
        class="transform-gpu"
        @enter="componentOpen = true"
        @after-leave="emit('close')"
    >
        <div
            v-if="backdropOpen"
            :class="{
                'items-center justify-center': type === 'popup',
            }"
            :data-backdrop="backdrop"
            :data-transition="type"
            class="fixed inset-0 z-50 flex items-end overscroll-contain"
            @click.self="onBackdropClick"
        >
            <transition
                :name="'v-modal-' + props.type"
                class="transform-gpu"
                @leave="backdropOpen = false"
            >
                <component
                    :is="component"
                    v-if="componentOpen"
                    :class="{
                        '-translate-y-8 scale-90': type !== 'full' && order > 1,
                        'h-full w-full': type === 'full',
                        '': type === 'modal',
                    }"
                    v-bind="$attrs"
                    @close="close"
                />
            </transition>
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { onMounted, provide, ref, watch } from 'vue'

const emit = defineEmits(['close', 'closing'])

defineOptions({
    inheritAttrs: false,
})

const props = defineProps({
    open: {
        type: Boolean,
        required: true,
    },
    component: {
        type: Object,
        required: true,
    },
    order: {
        type: Number,
        default: 0,
    },
    type: {
        type: String,
        default: 'popup',
    },
    backdrop: {
        type: Boolean,
        default: true,
    },
})

provide('close-current-modal', close)

const backdropOpen = ref(false)
const componentOpen = ref(false)

onMounted(() => {
    backdropOpen.value = true
})

function close() {
    emit('closing')
    componentOpen.value = false
}

function onBackdropClick() {
    if (props.backdrop) close()
}

watch(
    () => props.open,
    (open) => {
        if (!open) {
            close()
        }
    },
    { immediate: true }
)
</script>
