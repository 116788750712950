<!-- /resources/shared/components/ui/TabLayoutTabs.vue -->
<template>
    <div
        v-if="enabledTabs?.length > 1 || loading"
        ref="container"
        class="w-full"
    >
        <template v-if="loading">
            <div
                v-for="index in 3"
                :key="'loading-' + index"
                class="animate-skeleton select-none rounded-lg border-none bg-stone-800 px-3 py-2 text-base"
            ></div>
        </template>
        <template v-else>
            <template
                v-for="(tab, index) in tabs"
                :key="tab?.template ?? 'tab-' + index"
            >
                <slot
                    v-if="
                        tab &&
                        (tab.enabled !== undefined
                            ? toValue(tab.enabled)
                            : true)
                    "
                    :active="activeTab == tab.template"
                    :select="() => handleTabClick(tab.template)"
                    :tab="tab"
                >
                    <button
                        :class="[
                            activeTab == tab?.template
                                ? menuBtnSelectedClass
                                : menuBtnClass,
                            mainBtnClass,
                        ]"
                        :data-key="tab?.template ?? 'loading-' + index"
                        @click="handleTabClick(tab.template)"
                    >
                        <slot :name="tab?.template + '-title'">
                            {{ tab?.title }}
                        </slot>
                    </button>
                </slot>
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, toValue } from 'vue'
import {
    tabLayoutActiveTabInjection,
    tabLayoutTabsInjection,
} from '@/shared/types/injection'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    mainBtnClass: {
        type: String,
        default:
            'select-none whitespace-nowrap px-4 pb-2 text-base font-medium',
    },
    menuBtnSelectedClass: {
        type: String,
        default: 'border-b border-stone-950 text-stone-950',
    },
    menuBtnClass: {
        type: String,
        default: 'border-b border-stone-200 text-stone-400',
    },
})
const tabs = inject(tabLayoutTabsInjection)
const activeTab = inject(tabLayoutActiveTabInjection)
const router = useRouter()
const route = useRoute()

/**
 * Returns only the tabs that are enabled
 */
const enabledTabs = computed(
    () =>
        tabs?.value?.filter((tab) =>
            tab.enabled !== undefined ? toValue(tab.enabled) : true
        ) ?? []
)

function handleTabClick(template: string) {
    activeTab!.value = template
    router.replace({ query: { ...route.query, tab: template } })
    // if (props.scrollToPageSection) {
    //     const tabContent = comp.value?.querySelector(`#${template}`)
    //     if (tabContent) {
    //         window.scrollTo({
    //             top: tabContent.offsetTop - header.value?.clientHeight,
    //             behavior: 'smooth',
    //         })
    //     }
    // } else {
    //     activeTab.value = template
    // }
}
</script>

<style scoped></style>
