<template>
    <slot />
</template>

<script lang="ts" setup>
import { provide, ref } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import api from '@/shared/utils/api'

const props = defineProps({
    projectSlug: {
        type: String,
        default: () => undefined,
    },
    modelPlural: {
        type: String,
        default: () => undefined,
    },
    exclude: {
        type: [Array,String],
        default: () => [],
    },
    attachCategory: {
        type: String,
        default: () => undefined,
    },
})

const _searchValue = ref('')
const searchValue = ref('')
// const results = ref([
//     {
//         id: 1,
//         name: 'Test',
//     },
//     {
//         id: 2,
//         name: 'Test 2',
//     },
// ])

const { data: results, isLoading } = useQuery({
    queryKey: [
        'search',
        props.projectSlug,
        props.modelPlural,
        _searchValue,
        props.exclude,
        props.attachCategory
    ],
    queryFn: async () => {
        const response = await api.get(`/api/v1/search`, {
            params: {
                project_slug: props.projectSlug,
                model_plural: props.modelPlural,
                q: searchValue.value,
                exclude: props.exclude.join(','),
                attach_category: props.attachCategory,
            },
        })

        return response.data
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
})

provide('searchValue', searchValue)
provide('results', results)
provide('isLoading', isLoading)

watchDebounced(
    searchValue,
    async () => {
        _searchValue.value = searchValue.value
        // const response = await api.get(`/api/v1/search`, {
        //     params: {
        //         project_slug: props.projectSlug,
        //         model_plural: props.modelPlural,
        //         q: searchValue.value,
        //         exclude: props.exclude.join(','),
        //     },
        // })
        //
        // results.value = response.data
    },
    { debounce: 300, maxWait: 1000, immediate: true }
)
</script>

<style scoped></style>
