<template>
    <img
        v-if="imageSrc && !loading"
        :alt="alt"
        :class="[
            'select-none bg-cover bg-center bg-no-repeat object-cover',
            !disableClick
                ? 'cursor-pointer hover:scale-101 hover:shadow-lg'
                : '',
        ]"
        :src="imageSrc"
        @click="handleClick"
    />
    <div
        v-else
        :class="[
            loading ? 'animate-pulse' : 'duration-100',
            {
                'cursor-pointer hover:bg-stone-200': !disableClick,
            },
        ]"
        class="flex select-none items-center justify-center bg-stone-100"
        @click="handleClick"
    >
        <component
            :is="
                fallbackImageType === 'film'
                    ? FilmIcon
                    : fallbackImageType === 'character'
                      ? MaskIcon
                      : NoImage
            "
            v-if="!loading"
            class="w-6 text-stone-300 duration-75 hover:text-stone-400"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import NoImage from '@/shared/components/icons/NoImage.vue'
import MaskIcon from '@/shared/components/icons/MaskIcon.vue'
import FilmIcon from '@/shared/components/icons/FilmIcon.vue'

const emit = defineEmits(['upload'])

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    image: {
        type: Object,
        default: null,
    },
    fallbackImageType: {
        type: String,
        default: () => undefined,
    },
    src: {
        type: String,
        default: null,
    },
    alt: {
        type: String,
        default: 'image',
    },
    context: {
        type: Object,
        default: () => undefined,
    },
    disableClick: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'medium',
        validator: (value: string) =>
            ['thumbnail', 'small', 'medium', 'large', 'original'].includes(
                value
            ),
    },
})

const openImagePopup = inject('openImagePopup')

const imageSrc = computed(() => {
    if (props.src) return props.src
    if (props.image) {
        return (
            props.image[props.size] ||
            props.image.medium ||
            props.image.original
        )
    }
    return null
})

const handleClick = () => {
    if (props.disableClick) return
    if (openImagePopup) {
        openImagePopup(props.image, props.context)
    } else {
        emit('upload')
    }
}
</script>
